.slide-enter {
  opacity: 0;
  transform: translateY(600px) scale(0.6);
}
.slide-enter-active {
  opacity: 1;
  transition: all 250ms ease-out;
  transform: translateX(0) scale(1);
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translateY(-600px) scale(0.6);
}