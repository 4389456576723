@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .glass {
    background-color: rgba(255, 255, 255, .10);
    backdrop-filter: blur(15px);
  }

  .glass-above {
    /* background-color: rgba(0, 0, 0, .0); */
    /* backdrop-filter: blur(2px); */
  }
}

.active-butterfly {
  position: absolute;
  top: -10px;
  right: -20px;
  width: 60px;
  opacity: 0;
  transition: opacity 500ms;
}

.active-butterfly-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 26px;
  opacity: 0;
  transition: opacity 500ms;
}

.show-active-butterfly .active-butterfly,
.show-active-butterfly .active-butterfly-small {
  opacity: 1;
}